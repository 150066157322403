.content {
  background: white;
  padding-top: 75px;
  padding-left: 72px;
  justify-content: space-between;
  height: calc(100vh - 75px);
}

.title {
  color: #161616;
  font-family: CitrinaBold, sans-serif;
  font-size: 48px;
  line-height: 124%;
  letter-spacing: -0.4px;
}

.subTitleBlack {
  color: #161616;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 150%;
}

.subTitleBold {
  color: #161616;
  font-family: InterBold, sans-serif;
  font-size: 16px;
  line-height: 150%;
}

.subTitleOrange {
  color: #ea7100;
  font-family: InterSemiBold, sans-serif;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.09px;
  text-align: start;
}

.applyButton {
  width: 280px;
  height: 12px;
  padding: 16px;
  border-radius: 8px;
  background: #ea7100;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.applyButtonText {
  color: white;
  font-family: InterBold, sans-serif;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.09px;
}

.image {
  height: 100%;
  position: relative;
  clip-path: polygon(0px calc(100%),
      26.973px calc(100% - 1.321px),
      22.016px calc(100% - 2.54px),
      17.395px calc(100% - 4.491px),
      13.176px calc(100% - 7.108px),
      9.425px calc(100% - 10.325px),
      6.208px calc(100% - 14.076px),
      3.591px calc(100% - 18.295px),
      1.64px calc(100% - 22.916px),
      0.621px calc(100% - 27.873px),
      0.921px calc(100% - 29.873px),
      calc(20%) calc(24.873px),
      calc(20% + 0.2px) calc(25.873px),
      calc(20% + 0.4px) calc(23.916px),
      calc(20% + 3px) calc(19.295px),
      calc(20% + 6.208px) calc(14.076px),
      calc(20% + 9.425px) calc(10.325px),
      calc(20% + 13.176px) calc(7.108px),
      calc(20% + 17.395px) calc(4.491px),
      calc(20% + 22.016px) calc(2.54px),
      calc(20% + 26.973px) calc(1px),
      calc(20% + 35px) calc(0px),
      100% 0px,
      100% 100%);
}