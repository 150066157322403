.sideDiv {
    background-color: #FEF8EB;
    display: flex;
    width: 218px;
    min-height: 100vh;
    padding: 32px 0px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    padding-top: 105px;
}

.lettersContainer {
    border-radius: 94.595px;
    background: var(--Brand-Primary-500, #EA7100);
    padding: 13px;
}

.letters {
    color: var(--Branco, var(--Header-Color, #FFF));
    font-family: Inter, sans-serif;
    font-size: 21.622px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.nameTitle {
    color: var(--Gray-2, #4F4F4F);
    font-family: InterSemiBold, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 22px;
}

.nameSubtitle {
    color: var(--Gray-3, #828282);
    font-family: InterSemiBold, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.timePeriodText {
    color: var(--Gray-02, #3E322E);
    font-family: InterSemiBold, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-top: 32px;
    margin-bottom: 12px;
}

.grayBox {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--Gray-01, #EBEBEB);
    padding: 0px 8px;
    height: 30px;
}

.title {
    color: #EA7100;
    font-family: CitrinaBold, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: -0.28px;
}

.subtitle {
    color: var(--Gray-02, #3E322E);
    font-family: InterBold, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.09px;
    margin-top: 8px;
}

.alertsDiv {
    margin-top: 16px;
    width: calc(100vw - 400px);
    border-radius: 12px;
    border: 1px solid var(--Gray-01, #EBEBEB);
    padding: 12px 22px;
}

.alertsTitle {
    color: var(--Primary-01, #EA7100);
    font-family: InterSemiBold, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.alertsSubtitle {
    color: var(--Gray-02, #3E322E);
    font-family: InterSemiBold, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}