.stepTitle {
  color: var(--Gray-04, #161616);
  font-family: CitrinaBold;
  font-size: 40px;

  line-height: 124%;
  letter-spacing: -0.4px;
}

.stepSubtitle {
  color: #161616;
  text-align: center;
  font-family: InterSemiBold, sans-serif;
  font-size: 16px;
  line-height: 154%;
  margin-bottom: 50px;
}

.error {
  width: 100%;
  color: #ea7100;
  text-align: end;
  font-family: InterBold;
  font-size: 14px;
  line-height: 150%;
}

.button {
  border-radius: 8px;
  border: 2px solid #EA7100;
  padding: 8px;
  width: 218px;
  box-sizing: content-box;
  height: 28px;
  font-family: InterBold, sans-serif;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

.subtitle {
  color: #161616;
  font-family: InterSemiBold, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.input {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background: white;
  height: 34px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  color: #9ea1b1;
  padding: 8px;
  outline-color: #EA7100;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

}

.stateContainer {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background: white;
  height: 34px;
  padding: 8px 0px;
}

.stateInput {
  border: none !important;
  background: white;
  outline: none;
  font-family: Inter, sans-serif;
  font-size: 14px;
  color: #9ea1b1;
}

.nameTip {
  color: #161616;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 150%;
  margin-top: 8px;
}


.addButton {
  width: 170px;
  height: 44px;
  border-radius: 12px;
  cursor: pointer;
}

.radio {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-family: InterSemiBold, sans-serif;
  color: #3E322E;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input~.checkmark {
    color: white !important;
  }

  ;

  input:checked~.checkmark {
    background-color: #EA7100;
    border: 2px solid white;
  }

}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 6px;
  border: 2px solid #F0F0F0;
}

.addButtonText {
  color: #EA7100;
  font-family: InterBold, sans-serif;
  font-size: 14px;
  line-height: 150%;
}