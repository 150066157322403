.approveButton {
  height: 45px;
  border-radius: 8px;
  background:  #ea7100;
  cursor: pointer;
  color: white;
  font-family: InterBold;
  font-size: 18px;
  margin-bottom: 9px;
}

.rejectButton {
  height: 45px;
  border-radius: 8px;
  border: 1px solid var(--Gray-02, #3e322e);
  background: var(--Branco, #fff);
  cursor: pointer;
  color: #3E322E;
  font-family: "InterBold", sans-serif;
  font-size: 14px;

}