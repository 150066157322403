.title {
  color: #161616;
  font-family: CitrinaBold;
  font-size: 48px;
  line-height: 118%;
  letter-spacing: -0.48px;
  text-align: center;

   @media (max-width: 992px) {
   font-size: 36px;
  }
}

.subTitle {
  color: #161616;
  font-family: CitrinaBold;
  font-size: 32px;
  line-height: 124%;
  letter-spacing: -0.32px;
  text-align: center;

  @media (max-width: 992px) {
   font-size: 27px;
  }
}

.container {
  background: white;
  padding-top: 56px;
  padding-bottom: 56px;
  padding-left: 72px;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  @media (max-width: 1405px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 50px;
  }

    @media (max-width: 600px) {
   padding-left: 10px;
   padding-right: 10px;
  }
}

.map{
  @media (max-width: 992px) {
   width: 586px;
   height: 383px;
  }

   @media (max-width: 600px) {
   width: 85vw;
   height: auto;
  }
}
