.error {
  color: #EA7100;
  font-family: InterSemiBold;
  font-size: 13px;
  padding-left: 10px;
  text-align: end;
}

.input {
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background: white;
  height: 34px;
  font-family: Inter;
  font-size: 14px;
  color: #161616;
  padding: 8px;
  outline-color: #EA7100;
}

.input:disabled {
  background: #EBEBE4;
}

.authorizationText {
  color: #3e322e;
  font-family: Inter;
  font-size: 14px;
  line-height: 150%;
}
