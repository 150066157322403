.stepTitle {
  color: var(--Gray-04, #161616);
  font-family: CitrinaBold;
  font-size: 40px;

  line-height: 124%;
  letter-spacing: -0.4px;
  margin-bottom: 30px;
}

.stepSubtitle {
  color: #ea7100;
  text-align: center;
  font-family: CitrinaBold;
  font-size: 28px;
  line-height: 124%;
  letter-spacing: -0.28px;
  margin-bottom: 42px;
}

.error {
  width: 100%;
  color: #ea7100;
  text-align: end;
  font-family: InterBold;
  font-size: 14px;
  line-height: 150%;
}