.container {
    box-sizing: border-box;
    background: #EBEBEB;
    align-items: center;
    padding: 120px 50px 50px 50px;
    min-height: 100vh;
    height: 100vh;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: inline;
        width: 24px;
        height: 100%;
    }

    &::-webkit-scrollbar-track {
        background: #EBEBEB;
        margin-top: 72px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ea7100;
        border-radius: 20px;
        border: 8px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
}