.boxTitle {
    color: var(--Primary-03, #943D15);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.boxSubtitle {
    color: var(--Primary-01, #EA7100);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}