.alert-background {
  position: fixed;
  top: 73px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.10) 100%);
  backdrop-filter: blur(45px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}