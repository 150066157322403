.alert-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-container {
  width: 409px;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.alert-close-button {
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
  margin: 10px;
}

.alert-message {
  color: #3e322e;
  text-align: center;
  font-family: InterSemiBold;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.09px;
  margin: 20px;
}

.title {
  width: 224px;
  color: #ea7100;
  text-align: center;
  font-family: CitrinaBold;
  font-size: 24px;
  line-height: 124%;
  letter-spacing: -0.24px;
}

.character {
  border: 1.3px solid #ebebeb;
  color: #161616;
  border-radius: 12px;
  font-size: 22px;
}

.characterInactive {
  background: white;
}

.characterFilled {
  color: var(--primary-blue-dark);
}

.characterSelected {
  outline-color: #ea7100;
}

.error {
  width: 100%;
  color: #ea7100;
  text-align: end;
  font-family: InterBold;
  font-size: 14px;
  line-height: 150%;
}

.characterError {
  border: 1.3px solid #ea7100;
  background-color: #FEF8EB;
  color: #161616;
  border-radius: 12px;
  font-size: 22px;
}
