.containerLeaderSolution {
  display: grid;
  grid-template-columns: 516px 516px;
  gap: 22px;

  @media (max-width: 1100px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
}

.applyButton {
  width: 280px;
  height: 60px;
  border-radius: 8px;
  background: #ea7100;
  cursor: pointer;
  margin-top: 23px;

  /* &:hover {
    opacity: 0.8;
  } */
}

.applyButtonText {
  color: white;
  font-family: InterBold;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.09px;
}
