.carouselBanner {
  width: 100vw;
  padding: 96px;
  box-sizing: border-box;
  min-height: 100vh;
  background: #ffead9;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  @media (max-width: 1260px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
  }
}


.containerBoxesInfo {
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 100vh;
  margin-top: 42px;

  @media (max-width: 1260) {
    padding: 33px;
    gap: 22px;
    height: 100vh;
    max-height: 100vh;
  }
}

.containerBoxesInfoTitle {
  color: #3e322e;
  font-family: Citrina, sans-serif;
  line-height: 124%;
  text-align: center;
}

.container3Boxes {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1300px) {
    font-size: 16px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 24px;
  }
}

.containerLeaderSolution {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;

  @media (max-width: 1000px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
}

.costBenefitApplyButton {
  border-radius: 8px;
  background: #ea7100;
  color: white;
  height: 59px;
  padding: 16px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  font-family: InterBold, sans-serif;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.testimonials {
  flex-direction: column;
  padding: 50px 72px;
  background-color: white;
  flex: 1;
  min-height: 100vh;
}

.compliance {
  background: #FEF8EB;
  min-height: 100vh;
  gap: 12px;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
}

.section {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}

.title {
  color: #161616;
  font-family: CitrinaBold, sans-serif;
  line-height: 124%;
  letter-spacing: -0.4px;
}

.subTitle {
  color: white;
  font-family: CitrinaBold, sans-serif;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: -0.24px;
}

.applyButton {
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  background-color: #ea7100;
  padding: 16px 12px;
}

.applyButtonText {
  color: white;
  font-family: InterBold, sans-serif;
  line-height: 150%;
  letter-spacing: -0.09px;
  font-size: 18px,
}