.modalItemText {
  font-size: 20px;
  color: white;
  font-family: InterSemiBold, sans-serif;
  cursor: pointer;

  @media (max-width: 1000px) {
    font-size: 16px;
  }

  &:hover {
    color: #3E322E !important;
  }
}

.containerBox {
  min-height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 99.99vw;
}

.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 42px;

  @media (max-width: 900px) {
    padding: 0px 24px;
  }
}

.containerMenuOptions {
  display: flex;
  align-items: center;
  gap: 24px;
  width: auto;
  min-height: 25px;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 24px 0px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.agentButton {
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}

.loginButton {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: inherit;
  z-index: 30;
}

.switchLanguage {
  border-radius: 100px !important;
  height: 1px;
  padding: 4px;
  gap: 8px;
  border: 1px solid #dedfe4;
  margin-left: 12px;
}

.switchItem1 {
  border-radius: 100px !important;
  border: none !important;
}

.menuContainer {
  padding: 32px;
  display: flex;
  justify-content: flex-end;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  flex-direction: column;
}

.menuTitle {
  font-size: 16px;
  margin-bottom: 32px;
}

.menuItem {
  font-family: "Inter", sans-serif;
  font-size: 18px;

  &:hover {
    color: #Ea7100 !important;
  }
}