/* .stdropdown-item{
    color:  #9EA1B1;
}
.stdropdown-tool path{
    fill: #EA7100;
}
.stdropdown-container{
    border: none!important;
} */

.stdropdown-container {
    text-align: left;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
  }
  
  .stdropdown-menu::-webkit-scrollbar {
    width: 2px;
  }
  
  .stdropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .stdropdown-menu::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .stdropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .stdropdown-input {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }
  
  .stdropdown-menu {
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    background-color: #fff;
    z-index: 99;
  }
  
  .stdropdown-item {
    padding: 5px;
    cursor: pointer;
  }
  
  .stdropdown-item:hover {
    background-color: rgba(159, 195, 248, 0.4392156863);
  }
  
  .stdropdown-item.selected {
    background-color: #0d6efd;
    color: #fff;
  }
  
  .stdropdown-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .stdropdown-tag-item {
    background-color: #ddd;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
  }
  
  .stdropdown-tag-close {
    display: flex;
    align-items: center;
  }
  
  .stsearch-box {
    padding: 5px;
    background-color: transparent;
  }
  
  .stsearch-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }