.title {
  color: #161616;
  text-align: center;
  font-family: CitrinaBold, sans-serif;
  font-size: 48px;
  line-height: 118%;
  letter-spacing: -0.48px;
}

.label {
  color: #161616;
  font-family: InterSemiBold, sans-serif;
  font-size: 14px;
  line-height: 150%;
}

.input {
  border-radius: 8px;
  border: 1px solid var(--Gray-01, #ebebeb);
  background: white;
  height: 34px;
  margin-top: 8px;
  padding: 8px;
  box-sizing: content-box;
  font-family: InterSemiBold, sans-serif;
  font-size: 14px;
  color: #161616;
  line-height: 150%;
  outline-color: #ea7100;
}

.forgotPasswordText {
  color: var(--Gray-02, #3e322e);
  font-family: InterSemiBold, sans-serif;
  font-size: 14px;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
}

.continueButton {
  border-radius: 8px;
  background: var(--primary-orange);
  border: none;
  width: 280px;
  height: 44px;
  color: white;
  cursor: pointer;
  font-family: InterBold, sans-serif;
  font-size: 14px;
}

.showPasswordText {
  color: #3e322e;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.greenBox {
  border-radius: 12px;
  border: 1px solid #008145;
  background: #e5fff1;
  position: relative;
  padding: 10px 40px 10px 10px;
  gap: 10px;

  span {
    color: #008145;
    font-family: InterSemiBold, sans-serif;
    font-size: 16px;
    line-height: 150%;
  }
}

.error {
  color: rgb(255, 42, 68);
  font-family: InterSemiBold, sans-serif;
  font-size: 13px;
  text-align: end;
  margin-top: 0px;
}

.passwordRules {
  color: #161616;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 150%;
  margin-top: 22px;

  li{
    margin-left: 5px;
  }
}
