.button {
    border-radius: 8px;
    border: 2px solid #EA7100;
    padding: 8px;
    width: 218px;
    box-sizing: content-box;
    height: 28px;
    font-family: InterBold, sans-serif;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
}

.input {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    background: white;
    height: 50px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #9ea1b1;
    padding-left: 8px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }

}

.input:focus {
    border: 1px solid #EA7100;
}

.radio {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-family: InterSemiBold, sans-serif;
    color: #3E322E;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    input~.checkmark {
        color: white !important;
    }

    ;

    input:checked~.checkmark {
        background-color: #EA7100;
        border: 2px solid white;
    }

}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 6px;
    border: 2px solid #F0F0F0;
}


.error {
    width: 100%;
    color: #ea7100;
    text-align: end;
    font-family: InterBold, sans-serif;
    font-size: 14px;
    line-height: 150%;
}

.subtitle {
    color: #161616;
    font-family: InterSemiBold, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}