.containerColumns {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  }
}
