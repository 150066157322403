.content {
  background: white;
  padding-top: 75px;
  padding-left: 72px;
  justify-content: space-between;
  height: calc(100vh - 75px);
}


.title {
  color: #161616;
  font-family: CitrinaBold, sans-serif;
  font-size: 48px;
  line-height: 124%;
  letter-spacing: -0.4px;
}

.subTitleBlack {
  color: #3E322E;
  font-family: InterSemiBold, sans-serif;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.09px;
  text-align: start;
}

.subTitleOrange {
  color: #ea7100;
  font-family: InterSemiBold, sans-serif;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.09px;
  text-align: start;
}

.applyButton {
  width: 280px;
  height: 12px;
  padding: 16px;
  border-radius: 8px;
  background: #ea7100;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.applyButtonText {
  color: white;
  font-family: InterBold;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.09px;
}