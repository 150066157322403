.container {
    box-sizing: border-box;
    background: #EBEBEB;
    align-items: center;
    padding: 120px 50px 50px 50px;
    max-height: 100vh;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: inline;
        width: 24px;
        height: 100%;
    }

    &::-webkit-scrollbar-track {
        background: #EBEBEB;
        margin-top: 72px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ea7100;
        border-radius: 20px;
        border: 8px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
}

.stepTitle {
    color: var(--Gray-04, #161616);
    text-align: center;
    font-family: CitrinaBold, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    /* 49.6px */
    letter-spacing: -0.4px;
}