body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Citrina";
  src: url("./fonts/Citrina-Regular.woff2");
  font-style: normal;
}
@font-face {
  font-family: "CitrinaBold";
  src: url("./fonts/Citrina-Bold.woff");
  font-style: normal;
}
@font-face {
  font-family: "InterBold";
  src: url("./fonts/Inter-Bold.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.ttf");
  font-style: normal;
}
@font-face {
  font-family: "InterSemiBold";
  src: url("./fonts/Inter-SemiBold.ttf");
  font-style: normal;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --white: #fff;
  --black: #000;
  --primary-orange: #ea7100;
  --neutral-gray04: #161616;
}