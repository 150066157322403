.blackText {
    color: #161616;
    font-family: InterSemiBold;
    font-size: 14px;
    line-height: 150%;
    margin-top: 32px;
  }
  
  .downloadContainer {
    border: 1px solid #E4EcF5;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
  }
  
  .uploadText {
    color: #324054;
    font-family: InterSemiBold;
    font-size: 14px;
    line-height: 22px;
  }
  
  .downloadInfo {
    color: #71839B;
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.06px;
  }