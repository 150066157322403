.dateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 8px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.dateInput {
  width: 100%;
  height: 100%;
  margin-top: 8px;
}

.dateInput input {
  height: 25px;
  border: none;
  background: transparent;
  z-index: 1;
}

.icon {
  width: 30px;
}

.icon svg {
  margin-top: 3px;
}

textarea:focus,
input:focus {
  outline: none;
}
